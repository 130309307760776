@import url('https://fonts.googleapis.com/css?family=Karla:300,400,600,700,800&display=swap');

a{
    cursor: pointer;
}

.text-danger{
    color: red;
}
#demo-simple-select-required{
    display: flex;
    justify-content: center;
    align-items: center;
}
.MuiSvgIcon-root{
    padding: 3px;
}